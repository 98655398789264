// extracted by mini-css-extract-plugin
export var background = "layout-module--background--TSS-q";
export var bigDivTop = "layout-module--big-div-top--VT0An";
export var bottomScene = "layout-module--bottom-scene--8i5ox";
export var clouds = "layout-module--clouds--mdyr4";
export var container = "layout-module--container--RoCXy";
export var contents = "layout-module--contents--TgBqn";
export var droplet = "layout-module--droplet--xIQWS";
export var fall = "layout-module--fall--jvFh-";
export var headerBlock = "layout-module--header-block--Dfs+B";
export var heading = "layout-module--heading--3ayl9";
export var leaf = "layout-module--leaf--KoKBu";
export var logo = "layout-module--logo--0GmiV";
export var snsLinks = "layout-module--sns-links--ooBhK";
export var switcherToEn = "layout-module--switcher-to-en--UJGK3";
export var switcherToJa = "layout-module--switcher-to-ja--gs-xf";
export var wiggle1 = "layout-module--wiggle1--UUmLi";
export var wiggle2 = "layout-module--wiggle2--bF5am";
export var wiggle3 = "layout-module--wiggle3--vJ2s2";